<template>
  <v-container fluid>
    <v-layout align-top justify-center>
      <v-flex xs12 sm10 md8>
        <v-card width="100%" flat>
          <v-card-title> Pedido </v-card-title>
          <v-card-text class="pa-0">
            <!-- <v-data-table
              :headers="header[$vuetify.breakpoint.xsOnly]"
              :items="cart"
              :items-per-page="-1"
              item-key="index"
              hide-default-footer
              sort-by="name"
              mobile-breakpoint="0"
              class="table-cursor elevation-1"
              loading-text="Cargando ..."
            >
              <template v-slot:top></template>
              <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
                <div @click="editItm(item)">
                  <b class="text-uppercase">{{ item.product_name }}</b> -
                  {{
                    (parseFloat(item.pack) * item.size).toLocaleString(2) +
                    " " +
                    item.uom +
                    " x " +
                    item.qty
                  }}
                  <span v-html="item.description" />
                  <template v-if="item.product_options">
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="(value, key) in item.product_options"
                        :key="key"
                        class="pa-0"
                      >
                        <v-data-table
                          :headers="headerSelected"
                          hide-default-header
                          hide-default-footer
                          :items-per-page="-1"
                          mobile-breakpoint="0"
                          :items="
                            item.product_options[key].filter(function (rg) {
                              return rg.selected === true;
                            })
                          "
                          item-key="product_name"
                          :single-select="selecttype(item.product_options[key])"
                          dense
                        >
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
              <template v-slot:[`item.pack`]="{ item }" class="text-right">
                {{
                  (parseFloat(item.pack) * item.size).toLocaleString(2) + " " + item.uom
                }}
              </template>
              <template v-slot:[`item.qty`]="{ item }" class="text-right pa-0 ma-0">
                <v-btn text fab @click="editItm(item)">
                                    {{ parseFloat(item.qty) }}
                                </v-btn>
                {{ parseFloat(item.qty) }}
              </template>
              <template v-slot:[`item.uom`]="{ item }" class="text-right">
                {{
                  "$ " +
                  (
                    (parseFloat(item.price) - parseFloat(item.discount)) *
                    parseFloat(item.pack)
                  ).toLocaleString(2)
                }}
              </template>
              <template v-slot:[`item.tItem`]="{ item }" class="text-right">
                {{ "$" + parseFloat(item.tItem).toLocaleString() }}
              </template>
              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                                <v-btn icon fab @click="deleteItem(item)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
              <template slot="body.append">
                <tr>
                  <th>Totales</th>
                  <th class="text-center">
                                        {{ sumField('cart', "qty").toLocaleString(2) }}
                                    </th>
                  <th class="text-right">
                    {{ sumField("cart", "tItem").toLocaleString(2) }}
                  </th>
                </tr>
              </template>
            </v-data-table> -->

            <v-list width="100%">
              <v-list-item
                v-for="(child, index) in cart"
                :key="index"
                @click="editItm(child)"
              >
                <v-btn
                  v-if="child.discount"
                  color="success"
                  elevation="10"
                  rounded
                  dark
                  small
                  absolute
                  top
                  rigth
                  class="topleft"
                  >{{ "-" + child.percent + "%" }}</v-btn
                >
                <v-list-item-avatar size="80">
                  <v-img
                    contain
                    :src="getImagePhoto(child.account, child.store, child.picture)"
                    v-if="child.picture"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-card flat>
                    <v-card-title class="font-weight-bold pa-0">{{
                      child.product_name
                    }}</v-card-title>

                    <v-card-text
                      class="pa-0"
                      v-html="child.description"
                      v-if="child.description"
                    ></v-card-text>
                    <v-card-text class="pa-0" v-else>
                      {{ child.category + " / " + child.subcategory }}
                    </v-card-text>

                    <v-card-actions class="pa-0">
                      {{
                        "Cantidad: " + child.qty + " x " + child.pack + " " + child.uom
                      }}
                      <v-spacer></v-spacer>
                      {{
                        "$" +
                        Math.round(parseFloat(child.tItem)).toLocaleString(0) +
                        " x " +
                        child.uom
                      }}
                    </v-card-actions>
                    <v-btn
                      v-if="child.discount"
                      color="red"
                      elevation="0"
                      text
                      dark
                      small
                      absolute
                      bottom
                      right
                      class="btnprice"
                    >
                      <span class="text-decoration-line-through">{{
                        "$" + parseFloat(child.price).toLocaleString()
                      }}</span>
                    </v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="font-weight-bold pr-3" elevation="0">
            <v-spacer></v-spacer>Total : 
            {{ "$" + this.sc.total.toLocaleString() }}
          </v-card-actions>
          <v-card-actions elevation="0">
            <v-spacer></v-spacer>
            <v-btn  @click="send_order()">
              <span>Enviar Pedido</span>
              <v-icon>mdi-cube-send</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <CartAdd
      :dialog="edit_dialog"
      :itemSelected="itemSelected"
      :addItm="addItm"
      @close="
        edit_dialog = false;
        close();
      "
    />
    <v-bottom-navigation background-color="blue-grey lighten-5" grow app>
      <v-btn to="Inicio">
        <span>Lista de Precios</span>
        <v-icon>mdi-storefront-outline</v-icon>
      </v-btn>
      <v-btn to="MyOrders">
        <span>Recientes</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn @click="send_order()">
        <span>Enviar Pedido</span>
        <v-icon>mdi-cube-send</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import CartAdd from "../../components/Cart_add.vue";
import createItem from "../../utils/createItem.js";
export default {
  name: "Shoppingcart",
  components: { CartAdd },
  props: {},
  mounted() {
    if (localStorage.cart) {
      this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
      this.cart.forEach((itm) => {
        itm.qty = parseFloat(itm.qty);
        itm.price = parseFloat(itm.price);
        if (itm.value_options) {
          itm.value_options = parseFloat(itm.value_options);
        } else {
          itm.value_options = 0;
        }
        itm.tItem =
          parseFloat(itm.qty) *
          parseFloat(itm.pack) *
          (parseFloat(itm.price) - parseFloat(itm.discount));
      });
      console.log(this.cart);
    } else {
      this.cart = [];
    }
    this.updateTotals();
  },
  data() {
    return {
      edit_dialog: false,
      addItm: false,
      itemSelected: createItem(),
      header: {
        true: [
          {
            text: "",
            align: "center",
            value: "qty",
            width: "4%",
          },
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },

          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
        false: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
      },
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
        },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
      cart: [],
    };
  },
  methods: {
    close() {
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
          if (itm.value_options) {
            itm.value_options = parseFloat(itm.value_options);
          } else {
            itm.value_options = 0;
          }

          itm.tItem =
            parseFloat(itm.qty) *
            parseFloat(itm.pack) *
            (parseFloat(itm.price) - parseFloat(itm.discount));
        });
        this.updateTotals();
      } else {
        this.cart = [];
      }
    },
    getImagePhoto: function (account, store, src) {
      store = "000000";
      var url = "https://mayorista.app/photos/" + account + "/" + store + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    editItm(e) {
      e.options_selected = JSON.stringify(e.options_selected);
      this.itemSelected = { ...e };
      this.edit_dialog = true;
    },
    send_order() {
      //this.$emit("send", true);
      this.updateTotals();
      this.cart.forEach((itm) => {
        if (itm.product_options)
          itm.product_options = JSON.stringify(itm.product_options);
        delete itm.percent;
      });

      var order = {
        pos: this.$store.getters.company,
        party: this.$store.getters.profile,
        total: this.sc.total,
        items: this.cart,
      };

      order.total = this.sc.total;
      order.account = this.$store.getters.company.account;
      order.doc_type = "OR";
      order.store = this.$store.getters.company.code;
      order.userId = this.$store.getters.profile.code;
      order.agent = "WEB";
      order.items = this.cart;

      webserver("put_doc", order, (data) => {
        if (data.header) {
          this.cart = [];
          localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
        }
        this.$router.push("Checkout/?did=" + data.header.doc_code);
      });
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      this.updateTotals();
    },
    updateTotals() {
      var itms = this.cart;
      var qty = 0;
      var tax = 0;
      var total = 0;
      itms.forEach((itm) => {
        qty += parseFloat(itm.qty);
        if (itm.product_options) {
          var slt = itm.product_options;
          var tselected = 0;
          Object.keys(slt).forEach((key) => {
            slt[key].forEach((tm) => {
              if (tm.selected) {
                tselected += parseFloat(tm.price);
              }
            });
          });
          itm.value_options = tselected;
        }
        total += parseFloat(itm.tItem) + parseFloat(itm.value_options);
        tax +=
          ((parseFloat(itm.tItem) + parseFloat(itm.value_options)) *
            parseFloat(itm.tax)) /
          100;
      });
      this.sc.qty = qty;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = tax;
      this.sc.total = total;
    },
    selecttype(e) {
      return e[0].unique;
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
  watch: {
    item: function (e) {
      console.log(e);
    },
  },
};
</script>

<style></style>
